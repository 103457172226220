:root {
  --main-bg-color: #d6161d;
  --main-text-color: #161E1F;
  --highlight-color: yellow;
}

@media only screen and (min-width: 420px) {
  body {
    --header-font: 2.5em;
    --large-font: 1.7em;
    --small-font: 1.3em;
  }
}

@media only screen and (max-width: 420px) {
  body {
    --header-font: 1.6em;
    --large-font: 1.3em;
    --small-font: 1.1em;
  }
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

a {
  color: var(--main-text-color);
}

a:hover {
  color: var(--highlight-color);
  border-color: var(--main-text-color);
}

h1 {
  font-family: "Pirata One";
  font-size: 6em;
  margin: 0px;
}

h2 {
  font-size: var(--header-font);
  font-weight: 900;
  margin-top: 24px;
  margin-bottom: 0;
}

h3 {
  margin: 0px;
}

h2:first-child{
  margin-top: 0px;
}

div {
  box-sizing: border-box
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content{
  display: flex;
  flex-direction: column;
  max-width: 540px;
  gap: 16px;
  margin-bottom: 24px;
}

.blockWrapper {
  display: flex;
  flex-direction: row;
}

.blockWrapperLeft {
  flex: 3;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-right-width: 0px !important;
  padding: 8px;
}

.blockWrapperRight {
  background-color: var(--main-text-color);
  flex: 1;
  padding: 4px;
}

.blockWrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.linkBlock {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  gap: 4px;
}

.releaseWrapper {
  display: flex;
  flex-direction: column;
}

.socialWrapper {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  border-top: none !important;
}

.socialWrapper:not(:first-child) {
  border-left: none;
}

.socialBlockWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mainText {
  font-size: var(--large-font);
  font-weight: 600;
}

.subText {
  font-size: var(--small-font);
  font-size: 1em;
}

.link {
  font-size: 1.2em;
}

.divBorder {
  border-width: 4px;
  border-style: dashed;
}

.socialMediaWrapper {
 display: flex;
 flex-flow: row wrap;
 gap: 8px;
}

.socialMediaWrapper a{
  flex: 1;
}